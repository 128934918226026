import React from 'react';
import FilterComponent from '../FilterComponent';

import css from './ProductType.module.css';

const ProductType = props => {
  const {
    filterConfig,
    validQueryParams,
    intl,
    getHandleChangedValueFn,
    initialValues,
    listingTypes,
    liveEdit,
  } = props;

  return (
    <div className={css.root}>
      <FilterComponent
        idPrefix="SearchFiltersDesktop"
        className={css.filter}
        config={filterConfig}
        urlQueryParams={validQueryParams}
        initialValues={initialValues}
        getHandleChangedValueFn={getHandleChangedValueFn}
        intl={intl}
        listingTypes={listingTypes}
        liveEdit={liveEdit}
      />
    </div>
  );
};

export default ProductType;
